// Header.jsx
import React, { useState, useEffect } from "react";
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  Box,
  useTheme,
  useMediaQuery,
  Fade,
  styled
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { keyframes } from "@mui/system";

// Keyframes for animations
const shimmer = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isScrolled'
})(({ theme, isScrolled }) => ({
  background: isScrolled 
    ? 'rgba(0, 0, 0, 0.8)'
    : 'transparent',
  backdropFilter: isScrolled ? 'blur(8px)' : 'none',
  boxShadow: 'none',
  transition: 'all 0.3s ease-in-out',
}));

const Logo = styled(Typography)(({ theme }) => ({
  fontFamily: "'Edu AU VIC WA NT Hand', cursive",
  fontSize: '2.5rem',
  color: '#ffffff',
  textDecoration: 'none',
  position: 'relative',
  display: 'inline-block',
  textShadow: '0 0 10px rgba(255,255,255,0.3)',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    textShadow: '0 0 15px rgba(255,255,255,0.5)',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    background: 'rgba(255,255,255,0.3)',
    transform: 'scaleX(0)',
    transformOrigin: 'right',
    transition: 'transform 0.3s ease-in-out',
  },
  '&:hover::after': {
    transform: 'scaleX(1)',
    transformOrigin: 'left',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontFamily: 'DM Sans',
  fontWeight: '600',
  fontSize: '14px',
  padding: '10px 25px',
  color: '#fff',
  borderRadius: '0',
  border: '1px solid rgba(255,255,255,0.2)',
  backgroundColor: 'rgba(61, 63, 73, 0.3)',
  position: 'relative',
  overflow: 'hidden',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: 'rgba(91, 93, 104, 0.4)',
    borderColor: 'rgba(255,255,255,0.4)',
    transform: 'translateY(-2px)',
    boxShadow: '0 5px 15px rgba(0,0,0,0.2)',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(120deg, transparent, rgba(255,255,255,0.1), transparent)',
    animation: `${shimmer} 1.5s infinite`,
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  },
  '&:hover::before': {
    opacity: 1,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    padding: '8px 20px',
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'space-between',
  padding: theme.spacing(2, 3),
  maxWidth: theme.breakpoints.values.xl,
  margin: '0 auto',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1.5, 2),
  },
}));

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isScrolled, setIsScrolled] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const creatorLogin = () => {
    window.location.href = 'https://creator.kalakaar.io';
  };

  return (
    <StyledAppBar position="fixed" isScrolled={isScrolled}>
      <Fade in={isVisible} timeout={1000}>
        <StyledToolbar>
          <Box component={RouterLink} to="/" sx={{ textDecoration: 'none' }}>
            <Logo variant={isMobile ? "h5" : "h4"} component="span">
              kalakaar
            </Logo>
          </Box>
          <StyledButton
            onClick={creatorLogin}
            variant="contained"
            disableRipple
          >
            Creator Login
          </StyledButton>
        </StyledToolbar>
      </Fade>
    </StyledAppBar>
  );
};

export default Header;