import React from 'react';
import { Container, Box, Typography, Grid, styled } from '@mui/material';
import Header from "./header";
import Footer from "./footer";

// Previous styled components remain the same
const FloatingElement = styled(Box)(({ delay = 0 }) => ({
    position: 'absolute',
    width: '150px',
    height: '150px',
    background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)',
    animation: 'float 15s infinite',
    animationDelay: `${delay}s`,
    '@keyframes float': {
        '0%, 100%': {
            transform: 'translateY(0) rotate(0deg)',
        },
        '50%': {
            transform: 'translateY(-100px) rotate(180deg)',
        },
    },
}));

const StyledSection = styled(Box)(({ theme }) => ({
    position: 'relative',
    padding: theme.spacing(8, 0),
    color: '#FFF',
    zIndex: 1,
}));

const LinkedInButton = styled('a')(({ theme }) => ({
    display: 'inline-block',
    padding: '8px 16px',
    borderRadius: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: '#fff',
    textDecoration: 'none',
    transition: 'all 0.3s ease',
    marginTop: theme.spacing(2),
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        transform: 'scale(1.05)',
    },
}));

// Common typography styles
const SectionTitle = styled(Typography)(({ theme }) => ({
    fontSize: '2.5rem',
    fontWeight: 700,
    marginBottom: theme.spacing(4),
    '@media (max-width: 600px)': {
        fontSize: '2rem',
    },
}));

const SectionText = styled(Typography)(({ theme }) => ({
    fontSize: '1.25rem',
    lineHeight: 1.6,
    opacity: 0.9,
    '@media (max-width: 600px)': {
        fontSize: '1.1rem',
    },
}));

// New styled component for founder boxes
const FounderBox = styled(Box)(({ theme }) => ({
    height: '100%',
    textAlign: 'center',
    padding: theme.spacing(4),
    backgroundColor: 'rgba(255,255,255,0.05)',
    borderRadius: theme.shape.borderRadius * 2,
    display: 'flex',
    flexDirection: 'column',
}));

const OurStory = () => {
    return (
        <Box sx={{ backgroundColor: '#000', minHeight: '100vh', position: 'relative', overflow: 'hidden' }}>
            {/* Floating background elements */}
            <Box sx={{ position: 'absolute', inset: 0, overflow: 'hidden' }}>
                {[...Array(5)].map((_, i) => (
                    <FloatingElement
                        key={i}
                        delay={i * 1.5}
                        sx={{
                            left: `${i * 25}%`,
                            top: `${Math.random() * 100}%`,
                        }}
                    />
                ))}
            </Box>

            <Container>
                <Header />
            </Container>

            <Container maxWidth="lg">
                {/* Previous sections remain the same */}
                <StyledSection>
                    <SectionTitle align="center">
                        Why Kalakaar?
                    </SectionTitle>
                    <SectionText align="center" sx={{ maxWidth: '800px', mx: 'auto' }}>
                        In Hindi, "Kalakaar" means "artist." We believe that every creator is an artist at heart, and we are here to support their journey. Whether you're an independent filmmaker looking to showcase your work or a festival organizer bringing unique films to the world, Kalakaar is built for you.
                    </SectionText>
                </StyledSection>

                <StyledSection>
                    <SectionTitle align="center">
                        Our Story
                    </SectionTitle>
                    <SectionText align="center" sx={{ maxWidth: '800px', mx: 'auto' }}>
                        Born from a shared passion for storytelling and technology, Kalakaar empowers independent filmmakers and festival organizers with the digital tools they need to reach global audiences.
                    </SectionText>
                </StyledSection>

                {/* Updated Meet the Founders Section */}
                <StyledSection>
                    <SectionTitle align="center">
                        Meet the Founders
                    </SectionTitle>
                    <Grid container spacing={6} justifyContent="center" alignItems="stretch">
                        <Grid item xs={12} md={6}>
                            <FounderBox>
                                <Typography variant="h4" gutterBottom fontWeight={600}>
                                    Varun Saxena
                                </Typography>
                                <Typography variant="body1" sx={{ opacity: 0.9, fontSize: '1.1rem', lineHeight: 1.7 }}>
                                    A filmmaker and seasoned software engineer, Varun brings deep technical expertise to the world of film production. He leverages his dual background to create innovative solutions that bridge the gap between creators and their audiences.
                                </Typography>
                            </FounderBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FounderBox>
                                <Typography variant="h4" gutterBottom fontWeight={600}>
                                    Parmjeet Singh
                                </Typography>
                                <Typography variant="body1" sx={{ opacity: 0.9, fontSize: '1.1rem', lineHeight: 1.7 }}>
                                    With a unique blend of software industry expertise and dedication to Indian classical music, Parmjeet brings invaluable insight to Kalakaar. As an experienced organizer, performer, and volunteer in Indian music festivals, he combines technical excellence with an artist’s intuition to build a platform that empowers creators and strengthens audience connections.
                                </Typography>
                            </FounderBox>
                        </Grid>
                    </Grid>
                </StyledSection>

                <StyledSection>
                    <SectionTitle align="center">
                        Our Mission
                    </SectionTitle>
                    <SectionText align="center" sx={{ maxWidth: '800px', mx: 'auto' }}>
                        To create a seamless platform where independent cinema thrives, connecting passionate creators with engaged audiences worldwide.
                    </SectionText>
                </StyledSection>
            </Container>
            <Container>
                <Footer />
            </Container>
        </Box>
    );
};

export default OurStory;