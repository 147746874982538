
import { styled } from "@mui/system";
import { Box} from "@mui/material";

export const BlackBackground = styled(Box)({
    minHeight: "100vh",
});

export const MainContent = styled(Box)({
    fontFamily: "'DM Sans', sans-serif",
    color: "#e6e6e6",
});