import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { styled } from "@mui/system";
import Header from "./header";
import Footer from "./footer";
import { BlackBackground, MainContent } from '../common/utils';

// Reusing the floating element animation from hero section
const FloatingElement = styled(Box)(({ delay = 0 }) => ({
  position: 'absolute',
  width: '150px',
  height: '150px',
  background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)',
  animation: 'float 15s infinite',
  animationDelay: `${delay}s`,
  '@keyframes float': {
    '0%, 100%': {
      transform: 'translateY(0) rotate(0deg)',
    },
    '50%': {
      transform: 'translateY(-100px) rotate(180deg)',
    },
  },
}));

const EmailLink = styled('a')(({ theme }) => ({
  color: '#fff',
  textDecoration: 'none',
  fontSize: '1.25rem',
  fontWeight: 500,
  padding: '8px 16px',
  borderRadius: '8px',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  transition: 'all 0.3s ease',
  display: 'inline-block',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    transform: 'scale(1.05)',
  },
}));

const ContactUs = () => {
  return (
    <BlackBackground>
      <MainContent>
        <Container>
          <Header />
        </Container>
        
        {/* Background animation container */}
        <Box sx={{ 
          position: 'absolute', 
          inset: 0, 
          overflow: 'hidden',
          background: 'linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.8))',
        }}>
          {[...Array(5)].map((_, i) => (
            <FloatingElement
              key={i}
              delay={i * 1.5}
              sx={{
                left: `${i * 25}%`,
                top: `${Math.random() * 100}%`,
              }}
            />
          ))}
        </Box>

        <Container maxWidth="md" sx={{ 
          position: 'relative',
          minHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          zIndex: 1,
        }}>
          <Typography 
            variant="h2" 
            component="h1" 
            sx={{ 
              mb: 6,
              color: 'white',
              fontWeight: 700,
              textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
            }}
          >
            Contact Us
          </Typography>

          <Box sx={{ mb: 8 }}>
            <Typography 
              variant="h5" 
              sx={{ 
                mb: 3,
                color: 'rgba(255,255,255,0.9)',
                lineHeight: 1.6,
              }}
            >
              For Creators and Festival Organizers
            </Typography>
            <Typography 
              variant="body1" 
              sx={{ 
                mb: 2,
                color: 'rgba(255,255,255,0.8)',
                fontSize: '1.1rem',
              }}
            >
              Connect with us to showcase your work or organize festivals on our platform
            </Typography>
            <EmailLink href="mailto:contactus@kalakaar.io">
              contactus@kalakaar.io
            </EmailLink>
          </Box>

          <Box>
            <Typography 
              variant="h5" 
              sx={{ 
                mb: 3,
                color: 'rgba(255,255,255,0.9)',
                lineHeight: 1.6,
              }}
            >
              Technical Support
            </Typography>
            <Typography 
              variant="body1" 
              sx={{ 
                mb: 2,
                color: 'rgba(255,255,255,0.8)',
                fontSize: '1.1rem',
              }}
            >
              For platform issues or support requests
            </Typography>
            <EmailLink href="mailto:support@kalakaar.io">
              support@kalakaar.io
            </EmailLink>
          </Box>
        </Container>

        <Container>
          <Footer />
        </Container>
      </MainContent>
    </BlackBackground>
  );
};

export default ContactUs;