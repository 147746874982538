import React, { useState, useEffect } from "react";
import { Typography, Grid, Box, Container, useTheme, useMediaQuery } from "@mui/material";
import { Edit, CloudUpload, ConfirmationNumber } from '@mui/icons-material';
import { styled } from "@mui/system";
import { motion } from "framer-motion";

const FeatureSection = styled(Box)(({ theme }) => ({
  background: "linear-gradient(180deg, rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url('your-image-url-here')",
  backdropFilter: 'blur(10px)',
  padding: theme.spacing(8, 3),
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'url("/path-to-your-texture.jpg")',
    opacity: 0.1,
    mixBlendMode: 'overlay',
    pointerEvents: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 2),
  },
}));

const FeatureItem = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: theme.spacing(6),
  padding: theme.spacing(3),
  background: 'rgba(255, 255, 255, 0.03)',
  borderRadius: theme.spacing(1),
  backdropFilter: 'blur(5px)',
  transition: 'all 0.3s ease-in-out',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.05)',
    transform: 'translateY(-5px)',
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.3)',
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  color: '#ffffff',
  marginRight: theme.spacing(3),
  padding: theme.spacing(2),
  background: 'rgba(255, 255, 255, 0.05)',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: '#ffffff',
  fontWeight: 700,
  marginBottom: theme.spacing(2),
  position: 'relative',
  textShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -16,
    left: 0,
    width: 60,
    height: 3,
    background: 'linear-gradient(90deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 100%)',
  },
}));

const SectionDescription = styled(Typography)(({ theme }) => ({
  color: 'rgba(255, 255, 255, 0.9)',
  lineHeight: 1.8,
  letterSpacing: '0.3px',
  textShadow: '0 1px 2px rgba(0, 0, 0, 0.3)',
  marginBottom: theme.spacing(6),
}));

const Features = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsVisible(entry.isIntersecting),
      { threshold: 0.1 }
    );

    const element = document.getElementById('features');
    if (element) observer.observe(element);

    return () => observer.disconnect();
  }, []);

  const features = [
    {
      icon: <Edit />,
      title: "Create Your Festival",
      description: "Easily set up and customize your film festival with our intuitive interface."
    },
    {
      icon: <CloudUpload />,
      title: "Upload and Manage Content",
      description: "Securely upload, organize, and showcase your films and related materials."
    },
    {
      icon: <ConfirmationNumber />,
      title: "Ticketing and Payment",
      description: "Create, manage, and sell tickets effortlessly. Get paid directly through our secure platform."
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <FeatureSection id="features">
      <Grid container spacing={4}>
        <Grid item xs={12} md={5}>
          <SectionTitle
            variant={isMobile ? "h5" : "h4"}
            gutterBottom
          >
            Empower Your Creativity with Kalakaar
          </SectionTitle>
          <SectionDescription variant="body1">
            Kalakaar provides creators with powerful tools to bring their film festivals to life. 
            Our comprehensive platform streamlines every aspect of festival management, from content 
            curation to ticket sales, empowering you to focus on what matters most - showcasing great films.
          </SectionDescription>
        </Grid>
        
        <Grid item xs={12} md={7}>
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate={isVisible ? "visible" : "hidden"}
          >
            {features.map((feature, index) => (
              <FeatureItem
                key={index}
                variants={itemVariants}
              >
                <IconWrapper>
                  {React.cloneElement(feature.icon, { 
                    style: { fontSize: isMobile ? 24 : 32 } 
                  })}
                </IconWrapper>
                <Box>
                  <Typography
                    variant={isMobile ? "h6" : "h5"}
                    component="h3"
                    sx={{ 
                      color: '#ffffff',
                      fontWeight: 600,
                      marginBottom: 1
                    }}
                  >
                    {feature.title}
                  </Typography>
                  <Typography 
                    variant="body1"
                    sx={{ 
                      color: 'rgba(255, 255, 255, 0.85)',
                      lineHeight: 1.7,
                      letterSpacing: '0.3px'
                    }}
                  >
                    {feature.description}
                  </Typography>
                </Box>
              </FeatureItem>
            ))}
          </motion.div>
        </Grid>
      </Grid>
    </FeatureSection>
  );
};

export default Features;