import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Typography, Button } from '@mui/material';
import { BlackBackground, MainContent } from '../common/utils'
import { styled } from "@mui/system";

const HomePageButton = styled(Button)({
    fontFamily: "DM Sans",
    fontWeight: "600",
    fontSize: "14px",
    borderRadius: "4px",
    padding: "12px 25px",
    backgroundColor: "#3d3f49",
    "&:hover": {
      backgroundColor: "#5b5d68",
    },
  });

function NotFoundPage() {
    const navigate = useNavigate();

    return (
        <BlackBackground>
            <MainContent>
                <Container maxWidth="xl">
                    <Box sx={{ flexGrow: 1 }}>
                        <Box sx={{ py: 8, px: { xs: 2, sm: 4, md: 6 }, textAlign: 'center' }}>
                            <Typography variant="h2" component="h1" gutterBottom>
                                404
                            </Typography>
                            <Typography variant="h4" component="h2" gutterBottom>
                                Page Not Found
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Oops! The page you're looking for doesn't exist or has been moved.
                            </Typography>
                            <HomePageButton variant="contained" color="primary" onClick={() => navigate('/')}>
                                Go to Homepage
                            </HomePageButton>
                        </Box>
                    </Box>
                </Container>
            </MainContent>
        </BlackBackground>
    );
}

export default NotFoundPage;