import React from "react";
import { Typography, Grid, Box, Link, Divider, useTheme, useMediaQuery } from "@mui/material";
import { Instagram } from '@mui/icons-material';
import { styled } from "@mui/system";

// Styled components
const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#000',
  color: '#fff',
  width: '100%',
  position: 'relative',
  zIndex: 1,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#000',
    zIndex: -1,
  }
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: 'inherit',
  textDecoration: 'none',
  fontSize: '14px',
  '&:hover': {
    textDecoration: 'underline',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
  },
}));

const FooterBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2, 4),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <FooterContainer>
      <Box sx={{ 
        my: { xs: 2, sm: 3, md: 4 },
        width: '100%'
      }}>
        <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.12)' }} />
        <FooterBox>
          <Grid 
            container 
            spacing={{ xs: 2, sm: 4 }} 
            direction={isMobile ? "column" : "row"}
            alignItems={isMobile ? "center" : "flex-start"}
            sx={{ mb: isMobile ? 2 : 0 }}
          >
            <Grid item>
              <StyledLink href="/privacy">
                Privacy
              </StyledLink>
            </Grid>
            <Grid item>
              <StyledLink href="/terms">
                Terms of Service
              </StyledLink>
            </Grid>
            <Grid item>
              <StyledLink href="/ourstory">
                Our Story
              </StyledLink>
            </Grid>
            <Grid item>
              <StyledLink href="/contactus">
                Contact Us
              </StyledLink>
            </Grid>
          </Grid>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <StyledLink 
              href="https://www.instagram.com/kalakaario/" 
              sx={{ 
                display: 'flex', 
                alignItems: 'center',
                '&:hover': {
                  textDecoration: 'none',
                  opacity: 0.8,
                }
              }}
            >
              <Instagram sx={{ 
                fontSize: { xs: '20px', sm: '24px' },
                transition: 'opacity 0.2s',
                color: '#fff'
              }} />
            </StyledLink>
          </Box>
        </FooterBox>
        <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.12)' }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            py: { xs: 1.5, sm: 2 },
            px: { xs: 2, sm: 4 },
          }}
        >
          <Typography 
            variant="body2" 
            align="center" 
            sx={{ 
              fontSize: { xs: '11px', sm: '12px', md: '14px' },
              opacity: 0.8,
              color: '#fff'
            }}
          >
            © 2024 Kalakaar. All rights reserved
          </Typography>
        </Box>
      </Box>
    </FooterContainer>
  );
};

export default Footer;