import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Container,
  Fade,
  useTheme,
  useMediaQuery,
  styled
} from "@mui/material";
import { KeyboardArrowDown, Movie, Theaters } from "@mui/icons-material";
import CreatorAccessDialog from './creator-access-dialog';


// Styled components using MUI's styled API
const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 25,
  padding: '12px 24px',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  color: '#fff',
  transition: 'all 0.3s ease',
  textTransform: 'none',
  marginRight: theme.spacing(2),
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    transform: 'scale(1.05)',
  },
  [theme.breakpoints.down('sm')]: {
    marginRight: 0,
    marginBottom: theme.spacing(2),
  }
}));

const ScrollIndicator = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(8),
  left: '50%',
  transform: 'translateX(-50%)',
  cursor: 'pointer',
  animation: 'bounce 2s infinite',
  '@keyframes bounce': {
    '0%, 100%': {
      transform: 'translateX(-50%) translateY(0)',
    },
    '50%': {
      transform: 'translateX(-50%) translateY(10px)',
    },
  },
}));

const FloatingElement = styled(Box)(({ delay = 0 }) => ({
  position: 'absolute',
  width: '150px',
  height: '150px',
  background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)',
  animation: 'float 15s infinite',
  animationDelay: `${delay}s`,
  '@keyframes float': {
    '0%, 100%': {
      transform: 'translateY(0) rotate(0deg)',
    },
    '50%': {
      transform: 'translateY(-100px) rotate(180deg)',
    },
  },
}));

const Hero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isVisible, setIsVisible] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const scrollToFeatures = () => {
    document.getElementById('features')?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleShareStories = () => {
    setDialogOpen(true);
  };

  const watchFestivals = () => {
    window.location.href = 'https://watch.kalakaar.io/';
  };

  return (
    <Box
      sx={{
        minHeight: '90vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        background: 'linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.8))',
        overflow: 'hidden',
        mt: '64px',
      }}
    >
      {/* Animated background elements */}
      <Box sx={{ position: 'absolute', inset: 0, overflow: 'hidden' }}>
        {[...Array(5)].map((_, i) => (
          <FloatingElement
            key={i}
            delay={i * 1.5}
            sx={{
              left: `${i * 25}%`,
              top: `${Math.random() * 100}%`,
            }}
          />
        ))}
      </Box>

      <Container maxWidth="lg">
        <Fade in={isVisible} timeout={1000}>
          <Grid container spacing={4} justifyContent="center" alignItems="center">
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Typography
                variant="h1"
                sx={{
                  fontSize: { xs: '2.5rem', sm: '3rem', md: '4rem' },
                  fontWeight: 700,
                  color: 'white', // Changed to white
                  textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                  mb: 2,
                }}
              >
                Showcase Independent Cinema,
                <br />
                Explore New Stories
              </Typography>

              <Typography
                variant="h5"
                sx={{
                  fontSize: { xs: '1.1rem', sm: '1.3rem', md: '1.5rem' },
                  color: 'rgba(255,255,255,0.9)',
                  mb: 6,
                }}
              >
                A platform where independent cinema thrives, connecting passionate creators
                {!isMobile && <br />}
                with engaged audiences worldwide.
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  justifyContent: 'center',
                  gap: 2,
                }}
              >
                <StyledButton
                  variant="contained"
                  onClick={handleShareStories}
                  startIcon={<Movie />} // Icon for creators
                  sx={{
                    fontSize: { xs: '0.9rem', sm: '1rem', md: '1.2rem' },
                    padding: { xs: '8px 16px', sm: '10px 20px', md: '12px 24px' },
                  }}
                >
                  Launch Your Film or Festival
                </StyledButton>

                <StyledButton
                  variant="contained"
                  onClick={watchFestivals}
                  startIcon={<Theaters />} // Icon for audience
                  sx={{
                    fontSize: { xs: '0.9rem', sm: '1rem', md: '1.2rem' },
                    padding: { xs: '8px 16px', sm: '10px 20px', md: '12px 24px' },
                  }}
                >
                  Discover Films & Festivals
                </StyledButton>
              </Box>
            </Grid>
          </Grid>
        </Fade>
      </Container>

      <ScrollIndicator onClick={scrollToFeatures}>
        <KeyboardArrowDown sx={{ color: 'white', fontSize: 40 }} />
      </ScrollIndicator>
      <CreatorAccessDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </Box>

  );
};

export default Hero;