import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Button,
    Box,
    Typography,
    styled,
    alpha
} from '@mui/material';
import { Mail, Login } from '@mui/icons-material';

// Styled components
const StyledButton = styled(Button)(({ theme }) => ({
    width: '100%',
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center', // Changed to center
    justifyContent: 'flex-start',
    gap: theme.spacing(0.75), // Reduced gap between icon and text
    marginBottom: theme.spacing(2),
    textTransform: 'none',
    borderRadius: '12px',
    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
    '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 6px 20px rgba(0, 0, 0, 0.15)'
    }
}));

const CreatorAccessDialog = ({ open, onClose }) => {
    const handleCreatorLogin = () => {
        window.location.href = 'https://creator.kalakaar.io';
    };

    const handleContactUs = () => {
        window.location.href = 'mailto:contactus@kalakaar.io?subject=New Creator Access Request';
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    backgroundColor: 'rgba(18, 18, 18, 0.95)',
                    backdropFilter: 'blur(10px)',
                    borderRadius: '16px',
                    border: '1px solid rgba(255, 255, 255, 0.1)'
                }
            }}
        >
            <DialogTitle sx={{
                fontSize: '2rem',
                fontWeight: 600,
                pt: 4,
                pb: 2,
                color: 'white',
                textAlign: 'center'
            }}>
                Join the Kalakaar Community
            </DialogTitle>

            <DialogContent>
                <DialogContentText sx={{
                    mb: 4,
                    textAlign: 'center',
                    color: 'rgba(255, 255, 255, 0.7)'
                }}>
                    Share your cinematic vision with audiences worldwide
                </DialogContentText>

                <Box sx={{ mb: 3 }}>
                    <StyledButton
                        variant="contained"
                        onClick={handleCreatorLogin}
                        sx={{
                            bgcolor: 'rgba(255, 255, 255, 0.1)',
                            '&:hover': {
                                bgcolor: 'rgba(255, 255, 255, 0.15)'
                            }
                        }}
                    >

                        <Box sx={{ textAlign: 'center', flex: 1 }}>
                            <Login sx={{ color: 'white' }} />
                            <Typography variant="subtitle1" component="div" sx={{
                                fontWeight: 600,
                                color: 'white'
                            }}>
                                Returning Creator
                            </Typography>
                            <Typography variant="body2" sx={{
                                color: 'rgba(255, 255, 255, 0.7)'
                            }}>
                                Access your creator dashboard
                            </Typography>
                        </Box>
                    </StyledButton>

                    <StyledButton
                        variant="contained"
                        onClick={handleContactUs}
                        sx={{
                            background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #2196F3 40%, #21CBF3 100%)',
                            }
                        }}
                    >

                        <Box sx={{ textAlign: 'center', flex: 1 }}>
                            <Mail sx={{
                                color: 'white',
                                fontSize: '28px'
                            }} />
                            <Typography variant="subtitle1" component="div" sx={{
                                fontWeight: 600,
                                color: 'white',
                                fontSize: '1.1rem',
                                mb: 0.5
                            }}>
                                New Creator? Let's Connect!
                            </Typography>
                            <Typography variant="body2" sx={{
                                color: 'white',
                                opacity: 0.9,
                                mb: 1
                            }}>
                                Contact us to begin your journey
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: 'white',
                                    opacity: 0.9,
                                    fontSize: '0.9rem',
                                    lineHeight: 1.4,
                                    bgcolor: 'rgba(0, 0, 0, 0.2)',
                                    p: 1.5,
                                    borderRadius: '8px',
                                    border: '1px solid rgba(255, 255, 255, 0.1)'
                                }}
                            >
                                Introduce your project/festival to us at{' '}
                                <Typography
                                    component="span"
                                    sx={{
                                        color: '#fff',
                                        fontWeight: 600,
                                        textDecoration: 'underline',
                                        textUnderlineOffset: '2px'
                                    }}
                                >
                                    contactus@kalakaar.io
                                </Typography>
                            </Typography>
                        </Box>
                    </StyledButton>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default CreatorAccessDialog;