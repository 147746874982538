import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./components/landing-page"
import PrivacyPolicy from "./components/privacy";
import TermsOfService from "./components/terms";
import ContactUs from "./components/contact-us";
import NotFoundPage from "./components/not-found-page";
import OurStory from "./components/our-story";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/ourstory" element={<OurStory />} />
        {/* Add the catch-all route for 404 Not Found */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default App;