import React, { useEffect } from 'react';
import { Container, Box } from '@mui/material';
import { styled } from "@mui/system";
import Header from "./header";
import Footer from "./footer"
import { BlackBackground, MainContent } from '../common/utils'

const ContentContainer = styled(Container)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '2rem',
  '& > div': {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    padding: '2rem',
  },
});

const TermsOfService = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <BlackBackground>
      <MainContent>
        <Container>
          <Header />
        </Container>
        <ContentContainer maxWidth="lg">
          <div name="termly-embed" data-id="a5522558-4057-4a35-9fd3-4afdbadbec3c"></div>
        </ContentContainer>
        <Container>
          <Footer />
        </Container>
      </MainContent>
    </BlackBackground>
  );
};

export default TermsOfService;