import React from "react";
import { Container, Divider } from "@mui/material";
import Header from "./header";
import Hero from "./hero";
import Features from "./features";
import Footer from "./footer"
import { BlackBackground, MainContent } from '../common/utils'

const LandingPage = () => {
    return (
        <BlackBackground>
            <Container maxWidth="xl">
                <Header />
                <MainContent>
                    <Hero />
                    <Divider />
                    <Features />
                    <Divider />
                    <Footer />
                </MainContent>
            </Container>
        </BlackBackground>
    );
};

export default LandingPage;